<template>
    <!-- Footer Start -->
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 text-center">
                    All rights reserved © {{ currentYear }} - Key Academy Hub
                </div>
            </div>
        </div>
    </footer>
    <!-- end Footer -->
</template>

<script>
export default {
    setup() {
        const currentYear = new Date().getFullYear()

        return { currentYear }
    }
};
</script>

<style>
footer {
    background-color: #f5f6fa !important;
}
</style>