<template>
    <div class="avatar-md"
         :class="customClass">
        <span
            :class="`avatar-title bg-soft-${iconColor} text-${iconColor} font-20 rounded-circle`">
            {{ avatarText }}
        </span>
    </div>
</template>
<script>
export default {
    props: {
        avatarText : {
            type: String,
            default: 'ME'
        },
        iconColor: {
            type: String,
            default: 'success'
        }
    },
    components: {},
    setup() {

    },
    computed: {
        customClass() {
            return this.customClass ?? ''
        }
    }
}
</script>
<style scoped>
.avatar-md-150 {
    width: 150px;
    height: 150px;
}

.avatar-md-150 > .avatar-title {
    font-size: 50px !important;
}
</style>