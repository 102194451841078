<template>
    <!-- Topbar Start -->
    <div class="navbar-custom">
        <div class="container-fluid">
            <div class="logo-box" v-if="displayLogo">
                <a href="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="../../../../public/keyhub-logo.png" alt="" height="22"/>
                    </span>
                    <span class="logo-lg">
                        <img src="../../../../public/keyhub-logo.png" class="logoLg" alt="" height="40"/>
                    </span>
                </a>
            </div>

            <div class="school-details" v-if="schoolId !== 0">
                <div class="avatar-md">
                    <span
                        :class="`avatar-title bg-soft-success text-success font-20 rounded-circle`">
                        {{ schoolNameInitials }}
                    </span>
                </div>

                <div class="navHeaderBorder">
                    <div class="logo-box">
                        <a href="/" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="../../../assets/key-logo.png" alt="" height="22"/>
                            </span>

                            <span class="logo-lg">
                                <img src="../../../assets/key-logo.png" alt="" height="40"/>
                            </span>
                        </a>
                    </div>
                </div>

            </div>

            <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
                <li>
                    <button class="button-menu-mobile waves-effect waves-light" @click="handleButtonClick">
                        <i class="fe-menu"></i>
                    </button>
                </li>
            </ul>

            <div class="back-icon" v-if="schoolId === 0">
                <img src="../../../assets/images/icons/back_icon.svg" alt="" height="22" @click="navigateBack"
                     class="back-icon-image"/>

                <div class="avatar-md school-avatar mr-10">
                    <span
                        :class="`avatar-title bg-soft-success text-success font-20 rounded-circle`">
                        <div class="spinner-border text-success m-0" v-if="schoolNameLoader"></div>
                        {{ schoolNameInitials }}
                    </span>
                </div>

                <h4 class="semibold schoolName">{{ schoolName }}</h4>
            </div>

            <div class="d-flex align-items-center">
                <router-link :to="{ name: 'ListSchoolMessages' }"
                             :class="{ 'active semibold': route.name === 'ListSchoolMessages' }">
                    <img src="../../../assets/images/icons/message_icon.svg" alt="" height="50"
                         class="message-icon-image mr-3"/>
                </router-link>


                <ul class="list-unstyled topnav-menu mb-0 mr-40">
                    <li class="dropdown notification-list topbar-dropdown">
                        <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
                           data-toggle="dropdown"
                           href="#" role="button"
                           aria-haspopup="false" aria-expanded="false">

                            <div class="avatar-md m-0">
                            <span
                                :class="`avatar-title bg-soft-danger text-danger font-20 rounded-circle`">
                                {{ userInitials }}
                            </span>
                            </div>

                            <span class="pro-user-name ml-1">
                            <i class="mdi mdi-chevron-down"></i>
                        </span>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right profile-dropdown">
                            <!-- item-->
                            <div class="dropdown-header noti-title">
                                <h6 class="text-overflow m-0">Welcome {{ userName }} !</h6>
                            </div>

                            <!-- item-->
                            <router-link :to="{ name: 'SchoolProfile' }" class="dropdown-item notify-item">
                                <i class="fe-settings"></i>
                                <span> Settings </span>
                            </router-link>

                            <div class="dropdown-divider"></div>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item" @click="handleLogout">
                                <i class="fe-log-out"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="linear-loader" v-if="loading">
            <div class="train"></div>
        </div>
    </div>
    <!-- end Topbar -->
</template>

<script>
import {onMounted, ref, watch, watchEffect} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {
    capitalizeFirstLetter,
    getCurrentUser,
    getSchoolId,
    getUserInitials,
    getUserInitialsForName,
    getValidToken
} from "@/utils/helpers";
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";

export default {
    props: {
        loading: Boolean,
        displaySearch: Boolean,
        displayLogo: Boolean,
    },
    setup(props, {emit}) {
        const router = useRouter()
        const loading = ref(true)
        const currentLoggedInUser = ref(null)
        const userInitials = ref('')
        const userName = ref('')
        const schoolName = ref('')
        const schoolNameInitials = ref('')
        const schoolData = ref(null)
        const schoolNameLoader = ref(false)
        const route = useRoute()
        const schoolId = ref(0)

        onMounted(async () => {
            currentLoggedInUser.value = await getCurrentUser()

            schoolNameLoader.value = true
            await getSchoolDetails()
        })

        watch(() => currentLoggedInUser.value, () => {
            if (currentLoggedInUser.value.hasOwnProperty('school_id')) {
                if (Array.isArray(currentLoggedInUser.value.school_id)) {
                    schoolId.value = currentLoggedInUser.value.school_id[0]
                    userInitials.value = getUserInitialsForName(currentLoggedInUser.value.first_name, currentLoggedInUser.value.last_name)
                    userName.value = capitalizeFirstLetter(currentLoggedInUser.value.first_name, currentLoggedInUser.value.last_name)
                } else {
                    schoolId.value = currentLoggedInUser.value.school_id
                    userInitials.value = getUserInitialsForName(currentLoggedInUser.value.name)
                    userName.value = capitalizeFirstLetter(currentLoggedInUser.value.name)
                }
            } else {
                userInitials.value = getUserInitials(currentLoggedInUser.value.first_name, currentLoggedInUser.value.last_name)
                userName.value = capitalizeFirstLetter(currentLoggedInUser.value.first_name, currentLoggedInUser.value.last_name)
            }
        })

        function clearCookies() {
            let cookies = document.cookie.split(";");

            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            }

            console.log("Cookies cleared successfully!");
        }

        const handleLogout = () => {
            localStorage.clear()
            clearCookies()

            router.push({name: 'Login'})
        }

        watchEffect(() => {
            loading.value = props.loading;
        });

        const navigateBack = () => {
            router.push({name: 'SchoolsList'})
        }

        const getSchoolDetails = async () => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}`;

            try {
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                schoolNameLoader.value = true;

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    // console.log('result.data', result.data)

                    if (result.code === 200) {
                        schoolData.value = result.data

                        const schoolDetails = {
                            name: schoolData.value.name,
                            email: schoolData.value.email,
                            id: schoolData.value.id,
                            address: schoolData.value.address,
                            country: schoolData.value.country,
                            state: schoolData.value.state,
                            msisdn: schoolData.value.msisdn,
                            isDeactivated: schoolData.value.isDeactivated,
                            subcription_plan: schoolData.value.subcription_plan,
                            next_subscription_plan: schoolData.value.next_subscription_plan,
                            reg_number: schoolData.value.reg_number,
                            logo: schoolData.value.logo
                        }

                        localStorage.setItem('schoolDetails',
                            JSON.stringify(schoolDetails)
                        )

                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                schoolNameLoader.value = false;
            }
        }

        watch(schoolData, (item) => {
            schoolName.value = capitalizeFirstLetter(item.name)
            schoolNameInitials.value = getUserInitialsForName(item.name)
        })

        const handleButtonClick = () => {
            emit('toggle-menu')
        }

        return {
            handleLogout,
            loading,
            navigateBack,
            currentLoggedInUser,
            userInitials,
            userName,
            schoolName,
            schoolNameInitials,
            schoolNameLoader,
            handleButtonClick,
            route,
            schoolId
        }
    }
};
</script>

<style scoped>
.mr-40 {
    margin-right: 40px !important;
}

.navbar-custom {
    border-bottom: 1px solid #EBEDF3;
    padding: 0;
}

.navbar-custom > .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-user {
    display: flex !important;
    align-items: center;
}

.back-icon > img {
    cursor: pointer;
}

.logoLg {
    margin-left: 20px;
}

.back-icon {
    margin-left: 40px;
    display: flex;
    align-items: center;
}

.school-details {
    display: flex;
    align-items: center;
}

.school-details > .navHeaderBorder {
    border-left: 2px solid #EBEDF3;
    padding-left: 15px !important;
}

.avatar-md {
    margin-left: 40px;
    height: 50px;
    width: 50px;
    margin-right: 15px;
}

.top-search {
    border: 1px solid #f8fafc !important
}

.linear-loader {
    height: 8px; /* Adjust the height as needed */
    background-color: #89a6f3; /* Adjust the color as needed */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999; /* Ensure it's on top of other elements */
}

.train {
    width: 900px; /* Adjust the train's width */
    height: 8px; /* Should match the height of the loader */
    background-color: #2f5bcf; /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite; /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px; /* Start off the screen to the left */
    }
    100% {
        left: 100%; /* Move to the right of the screen */
    }
}

.topnav-menu-left {
    display: none;
}

.text-success {
    color: #2f5bcf !important;
}

@media (max-width: 767px) {
    .school-avatar {
        display: none;
    }

    .schoolName {
        margin-left: 0;
    }

    .back-icon {
        margin-left: 0;
    }

    .back-icon-image {
        display: none;
    }

    .topnav-menu-left {
        display: block;
    }
}

@media (max-width: 992px) {
    .topnav-menu-left {
        display: block;
    }
}

.nav-user {
    padding: 0 !important;
}
</style>