<template>
    <div class="form-content">
        <form @submit.prevent="updateItem">
            <div class="row mb-30">
                <div class="col-12">
                    <TextInputFieldVue :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter name of project',
                        inputFieldType: 'text',
                    }" labelText="Project Name" errorType="nameError" v-model="name" :isRequired="true" />
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter description here',
                        inputFieldType: 'description',
                    }" labelText="Description" errorType="descriptionError" v-model="description" :isRequired="true"/>
                </div>
            </div>

            <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="loading" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {getSchoolId, getValidToken} from "@/utils/helpers";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";

export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        SimpleDropdown,
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const name = ref(props.itemData.name);
        const description = ref(props.itemData.description);
        const accessToken = getValidToken();

        const showLoaders = () => {
            loading.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
        };



        const updateItem = async () => {
            const newItem = {
                name: name.value,
                description: description.value,
            }

            // dd(newItem)

            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/roles/${props.itemData.id}`
                showLoaders();

                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(newItem)
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    if (result.code === 200) {
                        const msg = {
                            apiResponse: "Role updated successfully",
                            type: "success"
                        }

                        emit("closeFormModal", msg);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        };



        return {
            loading,
            showLoaders,
            hideLoaders,
            name,
            description,
            updateItem,
        };
    },
};
</script>

<style>

</style>