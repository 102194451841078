<template>
    <div class="topnav p-0">
        <div class="container-fluid" v-if="isMenuOpen">
            <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

                <div class="collapse navbar-collapse" id="topnav-menu-content">
                    <ul class="navbar-nav">

                        <li class="nav-item gotAdminDashboard" v-if="currentUser === 'admin'">
                            <router-link :to="{ name: 'Dashboard' }" class="nav-link">
                                <i class="fe-home mr-1"></i>
                                <span> God Admin Dashboard </span>
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'SchoolDashboard' }" class="nav-link"
                                         :class="{ 'active semibold': route.name === 'SchoolDashboard' }">
                                <i class="fe-home mr-1"></i>
                                <span> School Dashboard </span>
                            </router-link>
                        </li>


                        <li class="nav-item" v-if="hasPermission('school_admin') || hasPermission('view_system_admin')">
                            <router-link :to="{ name: 'SchoolUsersList' }" class="nav-link"
                                         :class="{ 'active semibold': route.name === 'SchoolUsersList' || route.name === 'ShowSchoolUser' }">
                                <i class="fe-users mr-1"></i>
                                <span> Account Admins </span>
                            </router-link>
                        </li>

                        <li class="nav-item" v-if="hasPermission('view_learning_groups')">
                            <router-link :to="{ name: 'LearningGroupList' }" class="nav-link"
                                         :class="{ 'active': route.name === 'LearningGroupList' || route.name === 'ShowLearningGroup' }">
                                <i class="fe-globe mr-1"></i>
                                <span> Learning Groups </span>
                            </router-link>
                        </li>

                        <li class="nav-item" v-if="hasPermission('modify_teacher')">
                            <router-link :to="{ name: 'SchoolColearnersList' }" class="nav-link"
                                         :class="{ 'active semibold': route.name === 'SchoolColearnersList' || route.name === 'ShowSchoolColearner' }">
                                <i class="fe-users mr-1"></i>
                                <span> Co-Learners </span>
                            </router-link>
                        </li>

                        <li class="nav-item" v-if="hasPermission('view_parents')">
                            <router-link :to="{ name: 'SchoolParentList' }" class="nav-link"
                                         :class="{ 'active semibold': route.name === 'SchoolParentList' || route.name === 'ShowSchoolParent' }">
                                <i class="fe-users mr-1"></i>
                                <span> Parents </span>
                            </router-link>
                        </li>

                        <li class="nav-item" v-if="hasPermission('view_students')">
                            <router-link :to="{ name: 'SchoolStudentsList' }" class="nav-link"
                                         :class="{ 'active semibold': route.name === 'SchoolStudentsList' || route.name === 'ShowSchoolStudent' }">
                                <i class="fe-users mr-1"></i>
                                <span> Students </span>
                            </router-link>
                        </li>

                        <li class="nav-item" v-if="hasPermission('view_projects')">
                            <router-link :to="{ name: 'ProjectsList' }" class="nav-link"
                                         :class="{ 'active semibold': route.name === 'ProjectsList' || route.name === 'ShowProject' }">
                                <i class="fe-users mr-1"></i>
                                <span> Projects </span>
                            </router-link>
                        </li>

                        <li class="nav-item dropdown hidden" v-if="hasPermission('view_subscription')">
                            <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-settings" role="button"
                               :class="{ 'active semibold': route.name === 'SubscriptionDetail' || route.name === 'SchoolPlanSelection' }"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe-dollar-sign mr-1"></i> Manage Subscriptions
                                <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="topnav-settings">
                                <router-link :to="{ name: 'SubscriptionDetail' }" class="dropdown-item"
                                             :class="{ 'active semibold': route.name === 'SubscriptionDetail'}">
                                    Current Subscription
                                </router-link>
                                <router-link :to="{ name: 'SchoolPlanSelection' }" class="dropdown-item"
                                             :class="{ 'active semibold': route.name === 'SchoolPlanSelection'}">
                                    Available Plans
                                </router-link>
                            </div>
                        </li>

                        <li class="nav-item dropdown" v-if="hasPermission('view_roles') || hasPermission('view_permissions') || hasPermission('view_sme')">
                            <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-settings" role="button"
                               :class="{ 'active semibold': route.name === 'SchoolRolesList' || route.name === 'ShowSchoolRoles' || route.name === 'SMEList' || route.name === 'AcademicYearList'
                            || route.name === 'TermsList' }"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe-settings mr-1"></i> Access Controls
                                <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="topnav-settings">
                                <router-link :to="{ name: 'SchoolRolesList' }" class="dropdown-item" v-if="hasPermission('view_roles')"
                                             :class="{ 'active semibold': route.name === 'SchoolRolesList' || route.name === 'ShowSchoolRoles' }">
                                    Roles
                                </router-link>

                                <router-link :to="{ name: 'SchoolPermissionList' }" class="dropdown-item" v-if="hasPermission('view_roles')"
                                             :class="{ 'active semibold': route.name === 'SchoolPermissionList'}">
                                    Permissions
                                </router-link>

                                <router-link :to="{ name: 'SMEList' }" class="dropdown-item"
                                             :class="{ 'active semibold': route.name === 'SMEList'}">
                                    SMEs
                                </router-link>

                                <router-link :to="{ name: 'AcademicYearList' }" class="dropdown-item"
                                             :class="{ 'active semibold': route.name === 'AcademicYearList'}">
                                    Academic Years
                                </router-link>

                                <router-link :to="{ name: 'TermsList' }" class="dropdown-item"
                                             :class="{ 'active semibold': route.name === 'TermsList'}">
                                    Manage Terms
                                </router-link>
                            </div>
                        </li>


                    </ul> <!-- end navbar-->
                </div> <!-- end .collapsed-->
            </nav>
        </div> <!-- end container-fluid -->
    </div> <!-- end topnav-->
</template>
<script>
import {useRoute} from 'vue-router';
import SchoolRolesList from "@/views/School_Admin/Roles/SchoolRolesList.vue";
import {ref, watch} from "vue";
import {useUserStore} from "@/stores/user";
import {getCurrentUser, hasPermission} from "@/utils/helpers";

export default {
    props: {
        isMobileMenuOpen: Boolean
    },
    computed: {
        SchoolRolesList() {
            return SchoolRolesList
        }
    },
    setup(props) {
        const route = useRoute()
        const isMenuOpen = ref(props.isMobileMenuOpen);
        const currentUser = getCurrentUser()

        watch(() => props.isMobileMenuOpen, (newValue) => {
            isMenuOpen.value = newValue

            if (isMenuOpen.value) {
                setTimeout(() => {
                    feather.replace();
                }, 100)
            }
        })

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        return {
            route,
            isMenuOpen,
            hasPermission: checkPermission,
            currentUser
        }
    }
};
</script>
<style scoped>
.topnav {
    height: 60px;
    margin-top: 90px;
}

.topnav-menu {
    margin-right: 28px !important;
}

.nav-link.active {
    color: #0D44B2 !important;
}

.gotAdminDashboard {
    display: none;
}

@media (max-width: 767px) {
    .topnav {
        height: auto;
    }

    .topnav-menu {
        margin: 0 !important;
    }

    .topnav .nav-link {
        padding: 0.75rem 10px !important;
    }

    #topnav-menu-content {
        display: block !important;
    }

    .gotAdminDashboard {
        display: block;
    }
}

@media (max-width: 992px) {
    .topnav {
        height: auto;
    }

    .topnav-menu {
        margin: 0 !important;
    }

    .topnav .nav-link {
        padding: 0.75rem 10px !important;
    }

    #topnav-menu-content {
        display: block !important;
    }

    .gotAdminDashboard {
        display: block;
    }
}
</style>