import {defineStore} from "pinia";
import {getCurrentUser} from "@/utils/helpers";

export const useUserStore = defineStore('user', {
    state: () => ({
        user: getCurrentUser(),
        userLoading: false
    }),
    actions: {
        setUserDetails(newuser) {
            this.user = newuser
        }
    }
})