import {API_BASE_URL} from "@/config.js";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    formatDateTime,
    getSchoolId,
    getUserInitialsForName,
    getValidToken
} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const rolesData = ref([]);
const rolesPayloadData = ref(null);

const fetchRolesData = async (page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/school/${schoolId}/roles`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                rolesPayloadData.value = result.data
                rolesData.value = result.data.results

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        loading.value = false;
    }
};

const formattedRolesData = computed(() => {
    // Map and format the data
    return rolesData.value.map((role) => {

        // Format the created at
        const {date, time} = formatDateTime(role.created_at);

        const formattedRole = {
            user: {
                name: capitalizeFirstLetter(role.name),
                initials: getUserInitialsForName(role.name),
                avatarColor: 'info'
            },
            description: capitalizeFirstLetterInSentence(role.description),
            date,
            createdBy: capitalizeFirstLetter(role.creator_name)
        };

        return {
            ...formattedRole,
        };
    });
});


const getSchoolRoles = () => {
    return {rolesData, fetchRolesData, loading, formattedRolesData, rolesPayloadData}
}

export default getSchoolRoles