<template>
    <div class="form-group">
        <label class="label">
            {{ labelText }}
            <span class="required" v-if="isRequired">*</span>
        </label>
        <textarea
            :class="[fieldClass, errorMessage ? 'validError' : '']"
            class="rich-text-editor"
            :placeholder="config.placeholder"
            :value="modelValue"
            @input="updateValue($event.target.value)"
            rows="4"
            :disabled="isDisabled"
            :required="isRequired"
        ></textarea>

        <div class="invalid-feedback" v-if="errorMessage">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>


export default {
    props: {
        config: {
            type: Object,
            required: true,
        },
        errorMessage: String,
        labelText: String,
        modelValue: String,
        isDisabled: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {

        const updateValue = (newValue) => {
            emit("update:modelValue", newValue); // Emit the updated value
        };

        return {updateValue};
    },
    computed: {
        placeholderText() {
            return this.config.placeholder || "Enter Text";
        },
        fieldClass() {
            return this.config.class
                ? `form-control ${this.config.class}`
                : "form-control";
        },
        inputFieldType() {
            return this.config.type || "text";
        }
    },
};
</script>

<style scoped>
.label {
    font-weight: 400;
    font-size: 14px;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}

.required {
    color: red; /* or any other color you prefer */
    margin-left: 2px; /* Adjust spacing as needed */
    font-weight: bolder;
}

.validError {
    border-color: #f1556c !important;
}
</style>