import {ref} from 'vue';
import {API_BASE_URL} from "@/config.js";
import {getValidRefreshToken} from "@/utils/helpers";
import {useRouter} from "vue-router";

export default function () {
    const isRefreshing = ref(false);
    const apiUrl = `${API_BASE_URL}/auth/refresh-token`;
    const refresh_token = getValidRefreshToken();
    const router = useRouter()

    const refreshToken = async () => {
        try {
            isRefreshing.value = true;

            // Fetch the refresh token
            const response = await fetch(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${refresh_token}`,
                }
            });

            if (!response.ok) {
                console.error('Error refreshing token:', response.statusText);
                await router.push({name: 'Login'})

                throw new Error('Failed to refresh token');
            }

            // Assuming the response contains the new token
            const data = await response.json();
            const newToken = data.data.access_token;

            // TODO: Save the new token to localStorage
            document.cookie = `uid=${newToken}; path=/`

            // Token refreshed successfully
            return newToken;
        } catch (error) {
            console.error('Error refreshing token:', error);
        } finally {
            isRefreshing.value = false;
        }
    };

    return { isRefreshing, refreshToken };
}
