<template>
    <!-- Begin page -->
    <div id="wrapper">
        <Navbar :loading="loading" :displaySearch="false" :displayLogo="false"
                @toggle-menu="toggleMenu" />

        <TopNavbar :isMobileMenuOpen="mobileMenuStatus" />
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">
                <!-- Start Content-->
                <div class="container-fluid">
                    <FlashMessage :showAlert="showAlert" :alertType="alertType" :alertMessage="alertMessage" />
                    <router-view @data-loaded="closeLoader" @show-loader="showLoader" @flash-message="showMessage"/>
                </div>
                <!-- container -->
            </div>
            <!-- content -->

            <FooterView/>
        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->
    </div>
    <!-- END wrapper -->
</template>

<script>
import Navbar from "@/components/partials/SchoolAdmin/Navbar.vue";
import FooterView from "@/components/partials/GodAdmin/Footer.vue";
import TopNavbar from "@/components/partials/SchoolAdmin/TopNavbar.vue";
import FlashMessage from "@/components/common/FlashMessage.vue";
import {ref} from "vue";


export default {
    components: {Navbar, FooterView, TopNavbar, FlashMessage},
    setup() {
        const loading = ref(true)
        const alertType = ref("")
        const alertMessage = ref("")
        const showAlert = ref(false);
        const mobileMenuStatus = ref(true);

        const showMessage = (item) => {
            alertType.value = item.alertType
            alertMessage.value = item.alertMessage
            showAlert.value = true

            setTimeout(() => {
                showAlert.value = false;
            }, item.duration ?? 7000);
        }

        const showLoader = () => {
            loading.value = true
        }
        const closeLoader = () => {
            loading.value = false
        }

        const toggleMenu = () => {
            mobileMenuStatus.value = !mobileMenuStatus.value
        }

        return {
            closeLoader,
            loading,
            showLoader,
            showAlert,
            alertType,
            alertMessage,
            showMessage,
            mobileMenuStatus,
            toggleMenu
        }
    },
    mounted() {
        feather.replace();
    },
};
</script>

<style scoped>
.content > .container-fluid {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 100px;
    padding: 0 40px;
}

@media (max-width: 767px) {
    .content > .container-fluid {
        padding: 0 10px;
    }
}

.content-page {
    margin-left: 0 !important;
    overflow: hidden;
    padding: 0;
    min-height: 80vh;
    margin-top: 150px;
}

.content {
    padding-top: 30px;
}

.footer {
    bottom: 0;
    padding: 19px 15px 20px;
    position: absolute;
    right: 0;
    color: #98a6ad;
    left: 0;
    background-color: #eeeff3;
}
</style>