<template>
    <div class="form-content">
        <form @submit.prevent="saveNewItem">
            <div class="row mb-30">
                <div class="col-12">
                    <TextInputFieldVue :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter name of role',
                        inputFieldType: 'text',
                    }" labelText="Role Name" errorType="nameError" v-model="name" :isRequired="true" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter description here',
                        inputFieldType: 'description',
                    }" labelText="Description" errorType="descriptionError" v-model="description" :isRequired="true"/>
                </div>
            </div>

            <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="loading" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from "@/utils/helpers";
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";

export default {
    components: {
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const name = ref("");
        const description = ref("");
        const accessToken = getValidToken();

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const saveNewItem = async () => {
            const newItem = {
                name: name.value,
                description: description.value,
            }

            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/roles`
                showLoaders();

                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(newItem)
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    if (result.code === 200) {
                        const itemFullName = capitalizeFirstLetter(name.value)
                        emit("closeFormModal", itemFullName);
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', error)
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        };

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        return {
            loading,
            loadingSpinner,
            showLoaders,
            hideLoaders,
            name,
            description,
            saveNewItem,
        };
    },
};
</script>

<style>

</style>