import jwtDecode from 'jwt-decode'; // Make sure to install this package
import {ref} from 'vue';
import useRefreshToken from '@/utils/refreshToken';
import {getValidToken} from "@/utils/helpers";


const {refreshToken} = useRefreshToken();

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
    return getValidToken();
};

// Reactive variable to track token expiration
const isTokenExpired = ref(false);

// Function to check and update token expiration status
const checkTokenExpiration = () => {
    const token = getAuthToken();

    if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        isTokenExpired.value = decodedToken.exp < currentTime;
    } else {
        isTokenExpired.value = false;
    }
};


// Function to refresh the authentication token
const initRefreshToken = async () => {
    // Your logic to refresh the token goes here
    await refreshToken()

    // After refreshing, update the token expiration status
    checkTokenExpiration();
};

// Utility function to check token expiration and refresh if needed
const checkAndRefreshToken = async () => {
    checkTokenExpiration();

    if (isTokenExpired.value) {
        // Token is expired, refresh it
        await initRefreshToken();
    }
};

export {checkAndRefreshToken};
