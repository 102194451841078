<template>
    <div class="form-group" :class="fieldClass">
        <div class="custom-select-wrapper">
            <label :for="selectId" class="label">
                {{ labelText }}
                <span class="required" v-if="isRequired">*</span>
            </label>

            <select :id="selectId" v-model="selectedValue" @change="handleChange"
                    :disabled="isDisabled" :required="isRequired"
                    :class="errorMessage ? 'validError' : ''">

                <option v-for="(option, index) in options" :key="index" :value="option.value">
                    {{ option.label }}
                </option>
            </select>

            <div v-if="loading" class="linear-loader">
                <div class="train"></div>
            </div>

            <div class="invalid-feedback" v-if="errorMessage">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted, ref, watch} from 'vue';

export default {
    props: {
        labelText: {
            type: String,
            required: true,
            default: "Select Label"
        },
        options: {
            type: Array,
            required: true,
        },
        selectId: {
            type: String,
            default: 'example-select', // You can provide a default ID or let the parent pass one
        },
        modelValue: {
            type: [String, Number],
        },
        class: String,
        loading: Boolean,
        selectedItem: [String, Number],
        isDisabled: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        errorMessage: String,
    },
    setup(props, {emit}) {
        const selectedValue = ref(0)

        const handleChange = () => {
            emit('update:modelValue', selectedValue.value);
        };

        // Watch for changes in the selected item and update the selectedValue
        watch(() => props.selectedItem, (newVal) => {
            selectedValue.value = newVal;
        });

        onMounted(() => {
            // Call handleChange once the component is mounted to emit the initial value
            handleChange();

            if (props.options.length > 0) {
                selectedValue.value = props.options[0].value;
            }
        });

        const fieldClass = props.class || ''

        return {fieldClass, selectedValue, handleChange}
    }
}
</script>
<style scoped>
.label {
    font-weight: 400;
    font-size: 14px;
}

.form-control {
    background-color: #f8fafc !important;
    border: 1px solid #b8bcca !important;
    color: #333333 !important;
    padding: 10px;
}

/* Custom select */
.custom-select-wrapper {
    position: relative;
    display: block;
    width: 100%;
    /* Adjust the width as needed */
}

.custom-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 7.5px;
    border-radius: 4px;
    background-color: #f8fafc;
    border: 1px solid #D0D5DD;
    color: #333333;
}

.custom-select-wrapper::after {
    content: url("../../assets/images/icons/arrow_drop_down.svg");
    /* Replace with the path to your external SVG icon */
    width: 15px;
    /* Adjust the width of the icon as needed */
    height: 15px;
    position: absolute;
    top: 60%;
    right: 15px;
    /* Adjust the right value to control the position of the caret */
    transform: translateY(-50%);
    pointer-events: none;
}

.linear-loader {
    height: 2px;
    /* Adjust the height as needed */
    background-color: #b8bcca;
    /* Adjust the color as needed */
    width: 100%;
    position: relative;
    top: -2.5px;
    left: 0;
    right: 0;
    overflow: hidden;
}

.train {
    width: 900px;
    /* Adjust the train's width */
    height: 2px;
    /* Should match the height of the loader */
    background-color: #2f5bcf;
    /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite;
    /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px;
        /* Start off the screen to the left */
    }

    100% {
        left: 100%;
        /* Move to the right of the screen */
    }
}

.required {
    color: red; /* or any other color you prefer */
    margin-left: 2px; /* Adjust spacing as needed */
    font-weight: bolder;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}

.validError {
    border-color: #f1556c !important;
}


</style>